@use "../../../custom-theme.scss" as theme;
@use "@angular/material" as mat;

$fix-big-button-color: rgb(230, 230, 230);
$fix-big-button-border-radius: 1rem;
$fix-big-button-font-size: 1.2rem;

.survey-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-height: 700px) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.survey-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.survey-ready {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  flex-grow: 1;
  margin-top: 1rem;
}

.survey-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.survey-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.hp-color {
  color: mat.get-color-from-palette(theme.$heatbrain-primary, 500);
}

::ng-deep .mat-stepper-horizontal {
  background-color: transparent !important;
}

.stepper {
  max-width: 100%;
}

.button-padding {
  padding: 0.5rem 1.4rem;
}

.fix-control-button {
  font-size: 1rem;
  border-radius: 0.8rem;
  width: 10rem;
}

.loading {
  font-size: 1rem;
  font-weight: 700;
}

.id-title {
  font-size: 0.7rem;
  margin-bottom: 0px;
  line-height: 0.7rem;
}

.id-display {
  font-size: 0.5rem;
  margin-bottom: 0px;
  line-height: 0.5rem;
}

.survey-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.survey-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
  max-width: 100%;

  &.not-rendering {
    display: none;
  }
}

.survey-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
}

.stepper-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.step-label-header {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
}

.step-label-progress {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}

.report-button {
  font-size: 1rem;
  border-radius: 0.8rem;
  font-weight: 500;
  margin: 10px;
  margin-top: 15px;
  background-color: #fff;
  border: 3.5px solid #00007e;
  color: #00007e;
}

.question-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.question-control {
  width: 8rem;
}

.popup {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1rem 1.2rem;
  border-radius: 0.8rem;
  margin: 10px;
  margin-top: 40px;
  background-color: #fff;
  border: 3.5px solid #00007e;
  color: #00007e;
  height: 70px;
}

.popupcontent {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  z-index: 100;
  position: absolute;
  margin: 0;
  border-radius: 0;
  top: 0;
  left: 0;
  padding-top: 10%;
}

.popupwindow {
  display: block;
  width: 100%;
  max-width: 600px;
  border: 2px solid #00007e;
  background-color: white;
  margin: auto;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
}

.info-display {
  font-size: 0.9rem;
}

.email-display {
  position: relative;
}

.edit-email-icon {
  display: flex;
  position: absolute;
  left: Calc(100% + 8px);
  top: 0%;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #00007e;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
}

.icon {
  color: white;
  transform: scale(0.75);
}

.survey-time-ctrls {
  background-color: white;
  padding: 8px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  & button {
    width: 100%;
  }
}
