@use "../fix-survey.component.scss" as fix;
@use "../../../../custom-theme.scss" as theme;
@use "@angular/material" as mat;
@use "sass:math";

.multi-choice-cell {
  padding-left: 10px;
}

.multi-choice-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  max-width: 100%;
  flex-wrap: wrap;

  & .multi-choice-button {
    background-color: fix.$fix-big-button-color !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 11rem !important;
    height: 11rem !important;
    border-radius: fix.$fix-big-button-border-radius !important;
    border-style: none;

    &.active-selection {
      border-style: solid;
      border-width: 0.25rem;
      border-color: mat.get-color-from-palette(theme.$heatbrain-accent);
    }

    & span {
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: calc(2px + 1.4vw);
      margin-bottom: 0.1rem;
      font-weight: 600;

      @media (min-width: 862px) {
        font-size: 1.1rem;
      }
    }
  }
}

.text-input-question {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  & .text-input-svg {
    height: 7.5rem;
    width: 7.5rem;
  }

  & .text-input {
    height: 4.5rem;
    font-size: 2rem;
    width: 15rem;
    padding: 0.2rem 0.8rem;
    border: 3px solid mat.get-color-from-palette(theme.$heatbrain-accent) !important;
    border-radius: 10px;
    text-align: center;
  }

  & .clarification-text-input {
    height: 4.5rem;
    font-size: 1.5rem;
    width: 35rem;
    padding: 0.2rem 0.8rem;
    border: 3px solid mat.get-color-from-palette(theme.$heatbrain-accent) !important;
    border-radius: 10px;
    margin: 1rem;
  }
}

.boolean-choice-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & .text {
    font-size: 1.8rem;
  }

  & .boolean-switch {
    position: relative;
    display: flex;
    align-items: center;
    width: 9rem;
    height: 4.8rem;
    margin-bottom: 0;

    & input {
      display: none;
    }

    & input:checked + .boolean-slider {
      background-color: mat.get-color-from-palette(theme.$heatbrain-accent);
    }

    & input:focus + .boolean-slider {
      box-shadow: 0 0 4px mat.get-color-from-palette(theme.$heatbrain-accent);
    }

    & input:checked + .boolean-slider:before {
      -webkit-transform: translateX(-4.2rem);
      -ms-transform: translateX(-4.2rem);
      transform: translateX(-4.2rem);
    }
  }

  & .boolean-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    border-radius: 2.4rem;
  }

  & .boolean-slider:before {
    border-radius: 50%;
  }

  & .boolean-slider:before {
    position: absolute;
    content: "";
    height: 3.7rem;
    width: 3.7rem;
    right: 0.55rem;
    bottom: 0.55rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.comment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40rem !important;

  & .comment-area {
    width: 30rem;
    height: 14rem;
    padding: 1rem;
    font-size: 1.1rem;

    &:focus {
      outline: none !important;
      border: 2px solid mat.get-color-from-palette(theme.$heatbrain-accent) !important;
    }
  }
}

.checkbox-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.2rem;

  .container {
    display: flex;
    position: relative;
    height: 1.7rem;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .text {
    margin-left: 2rem;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.7rem;
    width: 1.7rem;
    background-color: #eee;
    border-radius: 0.3rem;
  }

  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .container input:checked ~ .checkmark {
    background-color: mat.get-color-from-palette(theme.$heatbrain-accent);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    position: absolute;
    left: 0.51rem;
    top: 0;
    width: 0.68rem;
    height: 1.36rem;
    border: solid white;
    border-width: 0 0.3rem 0.3rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.img-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;

  .img-upload-input {
    display: none;
  }

  .img-upload-label {
    padding: 0.7rem 1.5rem;
    border-radius: 1rem;
    background-color: mat.get-color-from-palette(theme.$heatbrain-primary);
    color: white;
    margin-bottom: 0;
  }

  .upload-button {
    padding: 0.7rem 1.5rem;
    border-radius: 0.8rem;
    background-color: mat.get-color-from-palette(theme.$heatbrain-primary);
    margin-bottom: 0;
    border: none;

    .upload-text {
      font-size: 1.2rem;
      color: white;
    }
  }

  .uploaded-img {
    max-height: 35vh;
    min-width: 10rem;
  }
}

$card-width: 220px;
$card-title-height: 65px;

.fix-overview-cards {
  width: 3 * $card-width + 60px;
  display: grid;
  grid-template-columns: repeat(auto-fill, $card-width);
  grid-gap: 20px;
  justify-content: space-between;
}

.fix-card {
  width: $card-width;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 4px #e5e5e5;
  margin-bottom: 20px;
  height: math.div($card-width, 4) * 3.5 + $card-title-height;
}

.fix-name {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  height: $card-title-height;
  width: $card-width;
  display: table-cell;
  vertical-align: middle;
}

.fix-image > img {
  width: $card-width;
  height: math.div($card-width, 4) * 3.5;
  opacity: 0.25;
  border-radius: 0px 0px 10px 10px;
}

.fix-choices {
  position: relative;
  padding: 10px;
  top: -1 * math.div($card-width, 4) * 3.5;
  display: table-cell;
  width: $card-width;
  height: math.div($card-width, 4) * 3.5;
  vertical-align: middle;
  font-size: 16px;
  line-height: 48px;
}

.fix-label {
  display: inline;
  position: relative;
  padding-left: 35px;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fix-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.custom-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px inset #00007e;
}

/* On mouse-over, add a grey background color */
.fix-label:hover input ~ .custom-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.fix-label input:checked ~ .custom-radio {
  background-color: #00007e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.fix-label input:checked ~ .custom-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.fix-label .custom-radio:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.fix-label.boolean-label,
.fix-label.multi-choice-label {
  line-height: 40px;
}

.sd-table__cell {
  width: 180px;
  height: 25px;
  max-height: 30px !important;
  padding-left: 10px;
}

.comment-area {
  border-radius: 10px;
}

.sd-rating__item-text > span {
  width: 40px;
  box-shadow: 0px 0px 6px 3px #e5e5e5;
  margin-right: 10px;
  height: 40px;
  display: table-cell;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.sq-root.hidden-question-container {
  border: none;
}

.sd-rating__item-text {
  margin-right: 20px;
}

.rounded-border {
  border-radius: 10px;
}

.active-rating {
  background-color: #20f246;
}

.hidden-led {
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
}

.fix-choices.tocht > label > .fix-name-text {
  font-size: 12px;
}
.fix-name {
  font-size: 16px;
  color: #00007e;
}
.sv-svg-icon {
  width: 25px;
  height: 25px;
}

.sjs_sp_placeholder {
  display: none;
}

h5 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #00007e;
  margin-top: 20px !important;
}

.sd-radio__svg {
  height: 45px;

  circle {
    visibility: hidden;
  }
}

.sd-matrix__cell {
  height: 25px;
  min-width: 100px;
  white-space: nowrap;
}

.sd-matrixdynamic {
  // font-size: 10px;

  .sd-dropdown {
    width: 100%;
    -webkit-appearance: listbox !important;
  }

  select.sd-dropdown {
    box-shadow: 0 0 3px #0000004f;
  }

  // .sd-input{
  //     width: 100%;
  // }

  // .sd-table__cell {
  //     width: 140px;
  //     height: 45px;
  // }

  // .sv-string-viewer {
  //     font-size: 10px;
  // }
}

.survey-container canvas {
  border: 1px solid gray;
}
