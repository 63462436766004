/* You can add global styles to this file, and also import other style files */
/* @import "../node_modules/c3/c3.min.css"; */

:root {
  --primary: #00007e;
  --on-primary: white;
  --secondary: #20f246;
  --on-secondary: black;
  --section-spacing: 32px;
  --std-border-radius: 14px;
  --btn-loading-grey: #d6d4d4;
  --contrast-grey: #f8fafa;
}

.mat-drawer-container {
  background-color: white !important;
}

body {
  margin: 0;
  font-family: Poppins, Roboto, "Helvetica Neue", sans-serif;
}

.file-drop-drop-zone {
  width: 100%;
  height: 100% !important;
  border: 2px dashed #00007e;
}

.file-drop-content {
  margin-top: 30%;
}

svg > polygon.deemphasized {
  opacity: 0.15;
}

i {
  cursor: pointer;
}

.map-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3rem;
  font-size: 0.9rem;
  color: black;
}

.tooltip-entry {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.tooltip-value {
  font-weight: 500;
}

.insulation-score-info {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.insul-score-color {
  height: 1rem;
  width: 1rem;
  background-color: blue;
  border-radius: 0.2rem;
}

.no-margin {
  margin: 0;
}

.primary-btn, .secondary-btn, .accent-btn {
  position: relative;
  margin-bottom: var(--section-spacing) !important;
  margin-bottom: 0 !important;
  padding: 0px 20px !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 99999px !important;
  box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
}

.accent-btn {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  border: 3px solid var(--secondary) !important;
  color: var(--on-secondary) !important;
}

.accent-btn:hover {
  opacity: 0.7;
}

.accent-btn:disabled {
  background-color: white !important;
  border-color: #d6d4d4 !important;
  color: #d6d4d4 !important;
}

.accent-btn .button-mat-spinner,
.primary-btn .button-mat-spinner,
.secondary-btn .button-mat-spinner
{
  position: absolute !important;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}


.primary-btn {
  background-color: var(--primary) !important;
  color: var(--on-primary) !important;
  border: 3px solid var(--primary) !important;
}

.secondary-btn {
  background-color: var(--on-primary) !important;
  color: var(--primary) !important;
  border: 3px solid var(--primary) !important;
}

.primary-btn:hover,
.secondary-btn:hover {
  opacity: 0.7;
}
