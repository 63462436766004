.sv_qstn .sq-root {
  border: 1px solid gray;
  border-left: 4px solid #00007e;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}

.sv_qstn2 .sq-root {
  border: 1px solid lightgray;
  border-radius: 5px;
  border-left: 4px solid #00007e;
}

.sq-title {
  font-size: 20px;
  margin-left: 20px;
}

.sq-label {
  margin-left: 30px;
}
.sq-item:nth-child(1) {
  margin-bottom: 5px;
}

.sv_q_title {
  font-size: 1.3rem !important;
  margin-bottom: 1rem;

  &.sq-title {
    display: flex;
    justify-content: center;
  }
}

.sv_main.sv_default_css {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  background-color: white !important;
}

.sd-matrixdynamic {
  & select.sd-dropdown {
    display: grid;
    box-sizing: border-box;
    appearance: none;
    background-color: #f9f9f9;
    border-radius: 0.3em;
    padding: 0.4em 0.2em;
    border: none;
    box-shadow: inset 0 0 3px #0000004f;
    outline: none;

    & option {
      background-color: #f9f9f9ed;
      border-radius: 0.3em;
    }
  }

  & select.sd-dropdown:focus {
    box-shadow: inset 0 0 3px #21f147;
    border-radius: 0.3em;
  }
}

.sd-matrixdynamic__btn {
  background-color: #f9f9f9;
  border: none;
  box-shadow: 0 0 3px 1px #0000004f;
  padding: 0.2em 0.7em;
  border-radius: 0.3em;

  &:hover {
    background-color: #21f147;
  }

  &:active {
    box-shadow: inset 0 0 5px #000000bb;
  }
}
